import logo from "./perfect-aim-software-solutions.png";
import "./Homepage.css";

function Homepage() {
  return (
    <div>
      <div class="container">
        <div class="row">
          <div class="col-sm"></div>
          <div class="col-sm logo">
            <img className="logo" alt="logo" src={logo} />
            <h1 className="comingsoon">Coming Soon</h1>
          </div>
          <div class="col-sm"></div>
        </div>
      </div>
    </div>
  );
}
export default Homepage;
